/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import { Col, Container, Row } from 'react-bootstrap';
import React, { useState, useEffect, useRef } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import * as S from './styles';
import SomoCollab from '../../../assets/images/home/quem-somo/somo-collab.svg';
import Selo from '../../../assets/images/selo.webp';
import Animation from '../../../assets/images/home/quem-somo/animation.json';

export default function QuemSomo() {
  const [scale, setScale] = useState(2.5);
  const [showContent, setShowContent] = useState(false);
  const EffectSomo = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const { scrollY } = window;
    const containerOffsetTop = EffectSomo.current?.offsetTop || 0;
    const distanceFromTop = scrollY - containerOffsetTop;
    const scaleRatio = Math.max(1 - distanceFromTop / 500, 0.8);
    setScale(scaleRatio * 1);
    setShowContent(scaleRatio <= 1);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <S.SomoEffectPadding ref={EffectSomo}>
      <Container id="quemSomo" className={`container-medium conteudo ${showContent ? 'fadein' : 'fadeout'}`}>
        <Row>
          <Col>
            <span className="sub-title mb-2 mb-lg-n5 d-block">QUEM SOMO</span>
          </Col>
        </Row>
      </Container>
      <S.SomoCollab className="px-2">
        <img
          id="somobig"
          src={SomoCollab}
          alt="Somo Collab"
          style={{ transform: `scale(${scale})` }}
          className="d-none d-lg-block"
        />
        <img
          id="somobig"
          src={SomoCollab}
          alt="Somo Collab"
          className="d-block d-lg-none w-100 mb-4"
        />
        <img
          id="selo"
          src={Selo}
          alt="Somo Collab"
          className={`conteudo d-none d-lg-flex ${showContent ? 'fadein' : 'fadeout'}`}
        />
      </S.SomoCollab>
      <S.Content>
        <Container className="container-medium">
          <Row>
            <Col lg={6} className="d-flex align-content-lg-start flex-column justify-content-end">
              <div className={`conteudo d-block mb-5 ${showContent ? 'fadein' : 'fadeout'}`}>

                <p>
                  A
                  {' '}
                  <strong>SOMO Collab</strong>
                  {' '}
                  é uma
                  {' '}
                  <span className="color-red">agência colaborativa</span>
                  {' '}
                  formada por profissionais de comunicação e tecnologia que somaram suas ideias e expertises para criar um
                  {' '}
                  <span className="color-red">novo modo de trabalhar e se conectar com as pessoas</span>
                  .
                </p>
                <p>
                  Inserimos os clientes nos processos de criação, criamos vínculos mais próximos, transparentes e dinâmicos ao longo de cada projeto, simplificando e fortalecendo as relações.
                </p>
                <p className="mb-0">
                  Essa horizontalidade somada à confiança, seriedade e visão, proporciona aos nossos clientes uma
                  {' '}
                  <span className="color-red">experiência única</span>
                  : mais leve, com propósito, além de resultados mais rápidos e efetivos que vão além dos números.
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <Player
                autoplay
                loop
                src={Animation}
              />
            </Col>
          </Row>
        </Container>
      </S.Content>
    </S.SomoEffectPadding>
  );
}
