import styled from 'styled-components';

export const SliderStyle = styled.div`
  padding-top: 60px;
  margin-bottom: 0px;
  .carousel-status{
    display: none;
  }
  .control-dots{
    display: none;
    .logos{
      height: 55px;
      img{
        height: 35px;
      }
    }
  }
`;
