/* eslint-disable max-len */
import {
  Col, Container, Row,
} from 'react-bootstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Player } from '@lottiefiles/react-lottie-player';
import * as S from './styles';
import Banner1 from '../../../assets/images/home/banner/banner1.webp';
import Banner2 from '../../../assets/images/home/banner/banner2.webp';
import Banner3 from '../../../assets/images/home/banner/banner3.webp';
import Banner4 from '../../../assets/images/home/banner/banner4.webp';

import Banner1Mobile from '../../../assets/images/home/banner/banner1-mobile.webp';
import Banner2Mobile from '../../../assets/images/home/banner/banner2-mobile.webp';
import Banner3Mobile from '../../../assets/images/home/banner/banner3-mobile.webp';
import Banner4Mobile from '../../../assets/images/home/banner/banner4-mobile.webp';

import Button from '../../../components/common/Button';
import Animation from '../../../assets/images/home/banner/animation.json';
import BannerComponent from './BannerComponent';

export default function Banner() {
  const banners = [
    {
      image: Banner1,
      imageMobile: Banner1Mobile,
      texto: 'Somar para ir <span class="yellow">além</span>.<br/>Somar para ser <span class="yellow">diferente</span>.<br/>Somar para <span class="yellow">evoluir</span>.',
      backgroundColor: 'bgred',
      buttonColor: 'black',
      buttonText: 'SOME COM A SOMO',
      buttonLink: '#footer',
      fontSize: 'f-size-62',
    },
    {
      image: Banner2,
      imageMobile: Banner2Mobile,
      texto: 'Somamos nossas<br /> <span class="yellow">Expertises</span> para<br />fazer a diferença',
      backgroundColor: 'bgpurple',
      buttonColor: 'yellow',
      buttonText: 'QUEM FAZ A SOMO ACONTECER',
      buttonLink: '#quemsoma',
      fontSize: 'f-size-88',
    },
    {
      image: Banner3,
      imageMobile: Banner3Mobile,
      texto: ' <span class="yellow">Design estratégico</span> para<br />somar com a sua marca.',
      backgroundColor: 'bgblack',
      buttonColor: 'yellow',
      buttonText: 'VEJA O QUE A SOMO FAZ',
      buttonLink: '#somofaz',
      fontSize: 'f-size-62',
    },
    {
      image: Banner4,
      imageMobile: Banner4Mobile,
      texto: ' O plus que a sua marca<br /> precisa para se destacar.',
      backgroundColor: 'bgred',
      buttonColor: 'black',
      buttonText: 'SAIBA O QUE A SOMO FAZ',
      buttonLink: '#somofaz',
      fontSize: 'f-size-62',
    },
  ];

  return (
    <div>
      <BannerComponent banners={banners} />
    </div>
  );
}
