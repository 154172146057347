import { Link } from 'react-router-dom';
import styled from 'styled-components';
import star from '../../assets/images/icons/start.svg';

export const FirstSection = styled.div`
    background: #fff;
    padding-top: 65px;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    /* padding:70px 30px 0; */
    white-space: normal !important;
    position: relative;
    .capa{
      height: 370px;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
    }
    h1{
      font-family: 'Antonio';
      font-weight: 700;
      font-size: 40px !important;
      line-height: 110%;
      color: #E8435D;
      margin-bottom: 19px;
      margin-top: 32px;
    }
    p{
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: #000000;
    }
    .tags{
      &.nohave-partner{
        margin-bottom: 25px;
      }
      &.have-partner{
        margin-bottom: 10px;
      }
      display: flex;
      flex-direction: column;
      width: fit-content;
      > div{
        display: inline-block;
        background: #F3F3F3;
        border-radius: 24px;
        padding: 5px 15px 10px 40px;
        margin-bottom: 10px;
        width: fit-content;
        &:last-child{
          margin-bottom: 0;
        }
        span{
          font-family: 'Antonio';
          font-size: 16px;
          display: flex;
          align-items: center;
          letter-spacing: 0.2em;
          color: #000000;
          position: relative;
          &:after{
            content: "";
            width: 16px;
            height: 1px;
            background-color: #FABA36;
            position: absolute;
            left: -25px;
            top: 11px;
          }
        }
      }
    }

    .parceiros{
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;
      > a{
        display: inline-block;
        background: #1E1E1E;
        border-radius: 24px;
        padding: 5px 15px 10px 40px;
        margin-bottom: 10px;
        width: fit-content;
        &:last-child{
          margin-bottom: 0;
        }
        span{
          font-family: 'Antonio';
          font-size: 16px;
          display: flex;
          align-items: center;
          color: #fff;
          position: relative;
          &:after{
            content: "";
            background: url(${star});
            width: 16px;
            height: 16px;
            position: absolute;
            left: -25px;
            top: 3px;
          }
        }
        &:hover{
          background: #FABA36;
          text-decoration: none;
          transition: 0.5s;
        }
      }
    }

    .animation{
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      > div{
        width: 120px;
        transform: rotate(90deg);
      }
    }
`;

export const Content = styled.div`
  background: #fff;
  img{
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0;
    }
  }
`;

export const MoreCases = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 200px;
  padding-bottom: 70px;
  a{
    font-family: 'Antonio';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    color: #000000;
    position: relative;
    transition:color 0.3s;
    &:after{
      content: '';
      width: 100%;
      height: 4px;
      background: rgba(0, 0, 0, 1);
      position: absolute;
      bottom: -20px;
      left: 0;
    }
    &:before{
      content: '';
      width: 0;
      height: 4px;
      background: ${({ theme }) => theme.colors.primary.main};
      position: absolute;
      bottom: -20px;
      left: 0;
      z-index: 5;
      transition:color 0.3s;
      transition-delay: 0.7s;
    }
    &:hover{
      color: ${({ theme }) => theme.colors.primary.main};
      transition:color 0.3s;
      transition-delay: 0.5s;
      text-decoration: none;
      &:before{
        width: 100%;
        transition: 1s;
      }
    }
  }
`;
