/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Button from 'components/common/Button';
import { useColorBackground } from 'Contexts/BannerCollor';
import { Player } from '@lottiefiles/react-lottie-player';
import * as S from './styles';
import Animation from '../../../assets/images/home/banner/animation.json';

interface BannerProps {
  banners: {
    image: string;
    imageMobile: string;
    texto: string;
    backgroundColor: string;
    buttonColor: string;
    buttonText: string;
    buttonLink: string;
    fontSize: string;
  }[];
}

function BannerComponent({ banners }: BannerProps) {
  const [index, setIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const { changeColorBackground } = useColorBackground();

  useEffect(() => {
    const interval = setInterval(() => {
      setOpacity(0);
      setTimeout(() => {
        setIndex((prev) => (prev + 1) % banners.length);
        setOpacity(1);
      }, 500);
    }, 6000);

    return () => clearInterval(interval);
  }, [banners]);

  useEffect(() => {
    changeColorBackground(banners[index].backgroundColor);
  }, [index]);

  return (
    <S.Banner className={banners[index].backgroundColor} style={{ position: 'relative' }}>
      <Container>
        <Row className="no-gutters">
          <Col lg={6} className="position-relative">
            {banners.map((banner, i) => (
              <S.BannerImage
                key={i}
                backgroundImage={banner.image}
                backgroundImageMobile={banner.imageMobile}
                style={{
                  width: '100%',
                  position: i === index ? 'relative' : 'absolute',
                  top: 0,
                  opacity: i === index ? opacity : 0,
                  visibility: i === index ? 'visible' : 'hidden',
                  transition: 'opacity 0.5s ease',
                }}
              />
            ))}
          </Col>
          <Col lg={6} className="d-flex align-items-start justify-content-center flex-column content-text">
            <div className="align-texts p-0 pl-lg-7">
              <div className="mb-container">
                <p className={banners[index].fontSize} dangerouslySetInnerHTML={{ __html: banners[index].texto }} />
              </div>
              <Button variant={banners[index].buttonColor} href={banners[index].buttonLink} placeholder={banners[index].buttonText} />
            </div>
          </Col>
          <S.Dots>
            {banners.map((_, i) => (
              <button key={i} className={` dot ${i === index ? 'selected' : ''}`} onClick={() => setIndex(i)} />
            ))}
          </S.Dots>
          <a className="effec-animation" href="/#somofaz">
            <Player
              autoplay
              loop
              src={Animation}
            />
          </a>
        </Row>
      </Container>
    </S.Banner>
  );
}

export default BannerComponent;
