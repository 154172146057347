/* eslint-disable max-len */
/* eslint-disable no-return-assign */
import { Col, Container, Row } from 'react-bootstrap';
import { useState, useRef, useEffect } from 'react';
import ASomoFaz from './ASomoFaz';
import Image from '../../assets/images/CasesPage/image.svg';
import Cloud1 from '../../assets/images/CasesPage/cloud1.svg';
import Cloud2 from '../../assets/images/CasesPage/cloud2.svg';
import Cloud3 from '../../assets/images/CasesPage/cloud3.svg';
import Cloud4 from '../../assets/images/CasesPage/cloud4.svg';
import Cloud5 from '../../assets/images/CasesPage/cloud5.svg';
import Plus1 from '../../assets/images/CasesPage/plus1.svg';
import Plus2 from '../../assets/images/CasesPage/plus2.svg';
import SomoCircle from '../../assets/images/CasesPage/somo-circle.svg';

import * as S from './styles';

const LeftCases = [
  {
    id: 1,
    image: 'https://somocollab.com/PORTFOLIOS/capas/marisa.webp',
    title: 'MARISA',
    subtitle: 'Endomarketing e Apresentações',
    link: '/cases/marisa',
  },
  {
    id: 2,
    image: 'https://somocollab.com/PORTFOLIOS/capas/mb.webp',
    title: 'Monte bravo',
    subtitle: 'Endomarketing, Apresentações e UX/UI',
    link: '/cases/monte-bravo',
  },
  {
    id: 3,
    image: 'https://somocollab.com/PORTFOLIOS/capas/tig_.webp',
    title: 'Tigé Boats',
    subtitle: 'UX/UI',
    link: '/cases/tige',
  },
  {
    id: 4,
    image: 'https://somocollab.com/PORTFOLIOS/capas/devyx.webp',
    title: 'Devyx',
    subtitle: 'UX/UI',
    link: '/cases/devyx',
  },
  {
    id: 5,
    image: 'https://somocollab.com/PORTFOLIOS/capas/dafiti.webp',
    title: 'Grupo Dafiti',
    subtitle: 'Foto e Vídeo',
    link: '/cases/dafiti',
  },
];

const RightCases = [
  {
    id: 1,
    image: 'https://somocollab.com/PORTFOLIOS/capas/good.webp',
    title: 'Goodstorage',
    subtitle: 'Endomarketing e Apresentações',
    link: '/cases/goodstorage',
  },
  {
    id: 2,
    image: 'https://somocollab.com/PORTFOLIOS/capas/lello.webp',
    title: 'Lello',
    subtitle: 'UX/UI',
    link: '/cases/lello',
  },
  {
    id: 4,
    image: 'https://somocollab.com/PORTFOLIOS/capas/eyou.webp',
    title: 'Eyou',
    subtitle: 'Branding e UX/UI',
    link: '/cases/eyou',
  },
  {
    id: 5,
    image: 'https://somocollab.com/PORTFOLIOS/capas/suntuo.webp',
    title: 'SUNTUO',
    subtitle: 'Branding',
    link: '/cases/suntuo',
  },
];

export default function Cases() {
  const [activeLeftIndexes, setActiveLeftIndexes] = useState<number[]>([]);
  const [activeRightIndexes, setActiveRightIndexes] = useState<number[]>([]);
  const leftBoxRefs = useRef<(HTMLDivElement | null)[]>([]);
  const rightBoxRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const handleScroll = () => {
      const newActiveLeftIndexes: number[] = [];
      const newActiveRightIndexes: number[] = [];

      leftBoxRefs.current.forEach((boxRef, index) => {
        if (boxRef) {
          const rect = boxRef.getBoundingClientRect();
          const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;

          if (isVisible && !activeLeftIndexes.includes(index)) {
            newActiveLeftIndexes.push(index);
          }
        }
      });

      rightBoxRefs.current.forEach((boxRef, index) => {
        if (boxRef) {
          const rect = boxRef.getBoundingClientRect();
          const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;

          if (isVisible && !activeRightIndexes.includes(index)) {
            newActiveRightIndexes.push(index);
          }
        }
      });

      setActiveLeftIndexes((prevActiveIndexes) => [...prevActiveIndexes, ...newActiveLeftIndexes]);
      setActiveRightIndexes((prevActiveIndexes) => [...prevActiveIndexes, ...newActiveRightIndexes]);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeLeftIndexes, activeRightIndexes]);

  return (
    <S.CasesPage>
      <img className="d-none d-lg-block c6" src={Cloud4} alt="" />
      <img className="d-none d-lg-block c7" src={Cloud3} alt="" />
      <img className="d-none d-lg-block p3" src={Plus1} alt="" />
      <ASomoFaz />
      <Container className="container-medium">
        <Row>
          <Col lg={12}>
            <S.Header>
              <img className="image" src={Image} alt="Vem ver o que a SOMO faz" />
              <p>
                Vem ver o que a
                <br />
                <span>SOMO faz</span>
              </p>
              <img className="object c1" src={Cloud1} alt="" />
              <img className="object c2" src={Cloud2} alt="" />
              <img className="d-none d-lg-block object c3" src={Cloud3} alt="" />
              <img className="object c4" src={Cloud4} alt="" />
              <img className="d-none d-lg-block object c5" src={Cloud5} alt="" />
              <img className="d-none d-lg-block object c5" src={Cloud5} alt="" />
              <img className="d-none d-lg-block object c5" src={Cloud5} alt="" />
              <img className="d-none d-lg-block object c5" src={Cloud5} alt="" />
              <img className="object p1" src={Plus1} alt="" />
              <img className="d-none d-lg-block object p2" src={Plus2} alt="" />
              <img className="d-none d-lg-block object sc" src={SomoCircle} alt="" />

            </S.Header>
          </Col>
          <Col md={12} lg={6}>
            {LeftCases.map(({
              image, title, subtitle, link, id,
            }, index) => (
              <S.Box
                key={id}
                className={`left ${activeLeftIndexes.includes(index) ? 'active' : ''}`}
                ref={(ref) => (leftBoxRefs.current[index] = ref)}
              >
                <a href={link}>
                  <img src={image} alt={title} />
                  <h3>{title}</h3>
                  <h4>{subtitle}</h4>
                </a>
              </S.Box>
            ))}
          </Col>
          <Col md={12} lg={6} className="mt-lg-9">
            {RightCases.map(({
              image, title, subtitle, link, id,
            }, index) => (
              <S.Box
                key={id}
                className={`right ${activeRightIndexes.includes(index) ? 'active' : ''}`}
                ref={(ref) => (rightBoxRefs.current[index] = ref)}

              >
                <a href={link}>
                  <img src={image} alt={title} />
                  <h3>{title}</h3>
                  <h4>{subtitle}</h4>
                </a>
              </S.Box>
            ))}
          </Col>
        </Row>
      </Container>
    </S.CasesPage>
  );
}
