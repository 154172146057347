import styled from 'styled-components';

export const CasesPage = styled.div`
  background-color: #fff;
  position: relative;
  padding-bottom: 150px;
  @media (max-width: 700px) {
    padding-bottom: 90px;
      }
  .c6{
    position: absolute;
    top: 50%;
    left: 5%;
    animation:slide-right 8s cubic-bezier(.25,.46,.45,.94) infinite alternate forwards;
  }
  .c7{
    position: absolute;
    top: 40%;
    right: -3%;
    animation:slide-left 12s cubic-bezier(.25,.46,.45,.94) infinite alternate forwards;
  }
  .p3{
      position: absolute;
      bottom: 15%;
      right: 7%;
      animation:rotate-center1 10s ease-in infinite alternate both;
    }
`;

export const Header = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 70px;
  position: relative;
  .image{
    margin-left: -40px;
  }
  p{
    font-family: 'Antonio';
    font-style: normal;
    font-weight: 700;
    font-size: 88px;
    line-height: 110%;
    text-align: center;
    color: #000000;
    margin-left: -20px;
    span{
      font-family: 'Antonio';
      font-style: normal;
      font-weight: 700;
      font-size: 88px;
      line-height: 110%;
      text-align: center;
      color: #E8435D;
    }
  }
  .object{
    position: absolute;
    &.c1{
      top: 10px;
      left: -15%;
      animation:slide-right 10s cubic-bezier(.25,.46,.45,.94) infinite alternate forwards;
    }
    &.c2{
      top: 200px;
      left: -30%;
      animation:slide-left 12s cubic-bezier(.25,.46,.45,.94) infinite alternate forwards;
    }
    &.c3{
      top: 250px;
      right: -3%;
      animation:slide-left 12s cubic-bezier(.25,.46,.45,.94) infinite alternate forwards;

    }
    &.c4{
      top: 0px;
      right: 0%;
      animation:slide-right 8s cubic-bezier(.25,.46,.45,.94) infinite alternate forwards;

    }
    &.c5{
      bottom: 50px;
      right: -30%;
      animation:slide-right 15s cubic-bezier(.25,.46,.45,.94) infinite alternate forwards;
    }
    &.p1{
      top: 50px;
      right: 20%;
      animation:rotate-center1 10s ease-in infinite alternate both;
    }
    &.p2{
      bottom: -50px;
      left: 60%;
      animation:rotate-center2 10s ease-out infinite alternate-reverse both;
    }
    &.sc{
      bottom: -400px;
      right: -15%;
      animation:rotate-center 5s linear infinite forwards;
     }
    @keyframes slide-right{0%{transform:translatex(0)}100%{transform:translatex(-50px)}}
    @keyframes slide-left{0%{transform:translatex(-50px)}100%{transform:translatex(50px)}}
    @keyframes rotate-center1{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}
    @keyframes rotate-center2{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}
    @keyframes rotate-center{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}
  }
  @media (max-width: 700px) {
    justify-content: center;
    padding-bottom: 100px;
    position: relative;
    .image{
      position: absolute;
      width: 180px;
      left: 35px;
      bottom: -20px;
    }
    p{
      font-size: 40px;
      span{
        font-size: 40px;
      }
    }
    .c1{
      width: 85px;
      top: 75% !important;
      left: 60% !important;
    }
    .c2{
      width: 85px;
      left: 70% !important;
    }
    .c4{
      top: 40% !important;
      width: 40px;
      right: 0 !important;
    }
    .p1{
      width: 20px;
      top: 50% !important;
      right: 40% !important;
    }
  }
`;

export const Box = styled.div`
    margin-bottom: 50px;
    &.left{
      transform: rotate(3deg) scale(0.9, 0.9);
    }
    &.right{
      transform: rotate(-3deg) scale(0.9, 0.9);
    }
    transition: 1s;
    &.active{
      transform: translate(0px, 0px);
      transition: 1s;
    }
  a{
    &:hover{
      text-decoration: none;
    }
  }
  h3{
    color: ${({ theme }) => theme.colors.black};
    font-family: 'Antonio';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 110%;
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-top: 25px;
  }
  h4{
    color: ${({ theme }) => theme.colors.black};
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    margin-bottom: 0px;
  }
  img{
    width: 100%;
  }
`;
