/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/style-prop-object */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Player } from '@lottiefiles/react-lottie-player';
import useOnScreen from '../../hooks/useOnScreen';
import './styles';

export default function GalleryItem({ src, width }) {
  const ref = useRef(null);
  const [reveal, setReveal] = useState(false);
  const onScreen = useOnScreen(ref);

  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);
  return (
    <div
      className={`gallery-item ${src.includes('LP') ? 'lp-full' : ''}`}
      data-scroll
      ref={ref}
    >
      <div className={cn('gallery-item-image', { reveal })}>
        {src.includes('LP') ? (
          <div className="gallery__item-imginner item-full">
            <div
              className="gallery__item-imginner"
              style={{ backgroundImage: `url(${src})` }}
            />
          </div>
        ) : src.includes('webp') ? (
          <div
            className="gallery__item-imginner"
            style={{ backgroundImage: `url(${src})`, width }}
          />
        ) : src.includes('json') ? (
          <Player
            className="animation"
            autoplay
            loop
            src={src}
            style={{ width }}
          />
        ) : src.includes('vimeo') ? (
          // @ts-ignore
          <div>
            <iframe width="750" height="476" src={src} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen />
            <div className="mask" />
          </div>

        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
