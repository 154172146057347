import styled from 'styled-components';

export const Portfolio = styled.div`
margin-top: 65px;
  > div{
    display: flex;
    flex-wrap: wrap;
  }
  .images{
    width: calc(20% - 8px);
    margin: 4px;
  }
  a{
    margin-bottom: 8px;
    display: inline-block;
    position: relative;
    width: 100%;
    img{
      width: 100%;
    }
    span{
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 18px;
      background: rgba(0, 0, 0, 0.8);
      display: flex;
      align-items: center;
      justify-content: start;
      padding: 0 24px;
      opacity: 0;
      height: 0px;
      width: 100%;
      transition: 0.3s;
      @media (max-width: 700px) {
        font-size: 16px;
        padding: 0 10px;
        justify-content: center;
      }
    }
    &.hover{
      span{
        height: 70px;
        opacity: 1;
        transition: 0.5s;
        @media (max-width: 700px) {
          height: 50px;
      }
      }
    }
  }
  @media (max-width: 700px) {
    .images{
      display: flex;
      min-width: 178px;
      flex-direction: column;
      a{
      max-width: 178px;
      height: 178px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    }
  }
`;

export const ControlWidth = styled.div`
  @media (max-width: 700px) {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    flex-wrap: nowrap !important;
    overflow-y: hidden;
  }
`;

export const ButtonPortfolio = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
`;
