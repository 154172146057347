import PortfolioMobile from 'components/PortfolioMobile';
import Portfolio from '../../components/Portfolio/index';
import { DataMarisa } from '../../components/Portfolio/data';

export default function Marisa() {
  return (
    <>
      <div className="d-none d-lg-block">
        <Portfolio data={DataMarisa} />
      </div>
      <div className="d-block d-lg-none">
        <PortfolioMobile data={DataMarisa} />
      </div>
    </>
  );
}
