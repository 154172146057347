import styled from 'styled-components';

export const QuemSomaContent = styled.div`
  margin-top: 130px;
  margin-bottom: 120px;
  h3{
    font-size: 88px;
    font-weight: bold;
    margin-bottom: 0;
  }
  @media (max-width: 700px) {
    h3{
      font-size: 56px !important;
    }
      margin-top: 60px;
      margin-bottom: 40px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1920px;
  margin: 0 auto;
`;
export const Box = styled.div`
  width: 33.33%;
  max-height: 640px;
  height: 100%;
  position: relative;
  @media (max-width: 700px) {
    width: 50%;
  }
  img{
    width: 100%;
    max-height: 621px;
  }
  &:after{
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50.52%, #000000 100%);
    left: 0;
    bottom: 0;
    transition: 0.5s;
  }

  .titles{
    position: absolute;
    z-index: 2;
    bottom: 34px;
    left: 34px;
    transition: 0.5s;
    p{
      margin-bottom: 5px;
      color: ${({ theme }) => theme.colors.primary.main};
      font-size: 32px;
    }
    span{
      font-weight: 300;
      font-size: 16px;
      text-transform: uppercase;
    }
    @media (max-width: 700px) {
      bottom: 5px;
      left: 9px;
      p{
        font-size: 18px;
        margin-bottom: 0;
      }
      span{
        font-size: 10px;
        line-height: 14px;
        display: block;
      }
    }
  }
  &.hover{
    &:after{
      height: 0%;
      transition: 0.5s;
    }
    .titles{
      opacity: 0;
      transition: 1s;
    }
  }
`;
