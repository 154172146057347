import PortfolioMobile from 'components/PortfolioMobile';
import Portfolio from '../../components/Portfolio/index';
import { DataSuntuo } from '../../components/Portfolio/data';

export default function Suntuo() {
  return (
    <>
      <div className="d-none d-lg-block">
        <Portfolio data={DataSuntuo} />
      </div>
      <div className="d-block d-lg-none">
        <PortfolioMobile data={DataSuntuo} />
      </div>
    </>
  );
}
