/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import * as S from './styles';
import SomoBig from '../../../../assets/images/home/somo-big.svg';
import SomoBigMobile from '../../../../assets/images/home/somo-big-mobile.svg';

export default function EffectSomo() {
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  function handleScroll() {
    const container = containerRef.current;
    const left = leftRef.current;
    const right = rightRef.current;

    if (container && left && right) {
      const containerTop = container.getBoundingClientRect().top;
      const windowTop = window.innerHeight / 2;
      const distanceFromTop = containerTop - windowTop;

      const maxTranslation = (container.offsetWidth - left.offsetWidth) / 2;
      const translation = Math.min(maxTranslation, Math.max(-maxTranslation, distanceFromTop * 2));

      const crossedTranslation = (distanceFromTop / window.innerHeight) * 3000;

      let leftValue = -translation - crossedTranslation;
      let rightValue = translation + crossedTranslation;

      if (screenWidth > 1920) {
        if (leftValue > -1000) {
          leftValue = 1510;
        }
        if (rightValue < 1000) {
          rightValue = -100;
        }
      }

      if (screenWidth <= 1920) {
        if (leftValue > -1000) {
          leftValue = 1050;
        }
        if (rightValue < 1000) {
          rightValue = -500;
        }
      }

      if (screenWidth < 1650) {
        if (leftValue > -1000) {
          leftValue = 950;
        }
        if (rightValue < 1000) {
          rightValue = -200;
        }
      }

      if (screenWidth < 1550) {
        if (leftValue > -1000) {
          leftValue = 850;
        }
        if (rightValue < 1000) {
          rightValue = -150;
        }
      }

      if (screenWidth < 1450) {
        if (leftValue > -1000) {
          leftValue = 750;
        }
        if (rightValue < 1000) {
          rightValue = -50;
        }
      }

      if (screenWidth < 1350) {
        if (leftValue > -1000) {
          leftValue = 650;
        }
        if (rightValue < 1000) {
          rightValue = 50;
        }
      }

      if (screenWidth < 1270) {
        if (leftValue > -1000) {
          leftValue = 450;
        }
      }

      left.style.transform = `translateX(${leftValue}px)`;
      right.style.transform = `translateX(${rightValue}px)`;
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isActive) {
          setIsActive(true);
        }
      },
      {
        rootMargin: '0px',
        threshold: 1.0,
      },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [isActive]);

  return (
    <>
      <S.Some className="d-none d-lg-block">
        <div ref={containerRef} className="crossed-divs-container">
          <div
            ref={rightRef}
            className="crossed-divs-right"
            style={{ top: `${screenWidth < 1350 ? '44px' : ''}` }}
          >
            <img
              src={SomoBig}
              alt="SOMO"
              width={screenWidth < 1350 ? '90%' : ''}
            />
          </div>
          <div ref={leftRef} className="crossed-divs-left">
            <span className="font-antonio">
              Some suas ideias
              <br />
              com a SOMO.
            </span>
          </div>
        </div>
      </S.Some>

      <S.SomeMobile className={`d-block d-lg-none ${isActive ? 'active' : ''}`} ref={ref}>
        <div className="position-relative">
          <div>
            <img src={SomoBigMobile} alt="SOMO" className="w-100" />
          </div>
          <div className="text">
            <span className="font-antonio w-100 d-block f-6">
              Some suas ideias
              com a SOMO.
            </span>
          </div>
        </div>
      </S.SomeMobile>
    </>
  );
}
