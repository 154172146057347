/* eslint-disable react/jsx-props-no-spreading */
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Button from 'components/common/Button';
import { useState } from 'react';
import * as S from './styles';

import NossosCases from '../../../assets/images/home/portfolio/nossos-cases.png';
import Marisa from '../../../assets/images/home/portfolio/marisa.webp';
import GoodStorage from '../../../assets/images/home/portfolio/goodstorage.webp';
import Lello from '../../../assets/images/home/portfolio/lello.webp';
import MonteBravo from '../../../assets/images/home/portfolio/montebravo.webp';
import Tig from '../../../assets/images/home/portfolio/tig.webp';
import Devyx from '../../../assets/images/home/portfolio/devyx.webp';
import Eyou from '../../../assets/images/home/portfolio/eyou.webp';
import Dafiti from '../../../assets/images/home/portfolio/dafiti.webp';
import Suntuo from '../../../assets/images/home/portfolio/suntuo.webp';

export default function Portfolio() {
  const [hoverPortfolio, setHoverPortfolio] = useState(null);

  const handleMouseEnter = (index) => {
    setHoverPortfolio(index);
  };

  const handleMouseLeave = () => {
    setHoverPortfolio(null);
  };

  const Portfolios = [
    {
      image1: NossosCases,
      text1: null,
      url1: '/cases',
      image2: Tig,
      text2: 'TIGÉ • UI/UX WEBSITE',
      url2: '/cases/tige',
    },
    {
      image1: Marisa,
      text1: 'MARISA •  COMUNICAÇÃO CORPORATIVA',
      url1: '/cases/marisa',
      image2: Devyx,
      text2: 'DEVYX • UI/UX WEBSITE',
      url2: '/cases/devyx',
    },
    {
      image1: GoodStorage,
      text1: 'GOODSTORAGE • COMUNICAÇÃO CORPORATIVA',
      url1: '/cases/goodstorage',
      image2: Eyou,
      text2: 'EYOU • IDENTIDADE VIUSAL E UI/UX',
      url2: '/cases/eyou',
    },
    {
      image1: Lello,
      text1: 'LELLO • UI/UX APP',
      url1: '/cases/lello',
      image2: Dafiti,
      text2: 'DAFITI • FOTO E VÍDEO',
      url2: '/cases/dafiti',
    },
    {
      image1: MonteBravo,
      text1: 'MONTE BRAVO • APRESENTAÇÃO<br class="d-none d-lg-block"/> E UI/UX LPS',
      url1: '/cases/monte-bravo',
      image2: Suntuo,
      text2: 'SUNTUO • IDENTIDADE VISUAL',
      url2: '/cases/suntuo',
    },

  ];

  return (
    <div id="cases">
      <Container className="px-0">
        <S.Portfolio>
          <S.ControlWidth>
            {Portfolios.map(({
              image1, text1, image2, text2, url1, url2,
            }) => (
              <div className="images" key={text1}>
                <a
                  href={url1}
                  className={`${(hoverPortfolio === text1 && text1 !== null) ? 'hover' : ''}`}
                  onMouseEnter={() => handleMouseEnter(text1)}
                  onMouseLeave={handleMouseLeave}
                >
                  <img src={image1} alt={text1} />
                  <span className="font-antonio" dangerouslySetInnerHTML={{ __html: text1 }} />
                </a>
                <a
                  href={url2}
                  className={`${(hoverPortfolio === text2 && text2 !== null) ? 'hover' : ''}`}
                  onMouseEnter={() => handleMouseEnter(text2)}
                >
                  <img src={image2} alt={text2} />
                  <span className="font-antonio" dangerouslySetInnerHTML={{ __html: text2 }} />
                </a>
              </div>
            ))}
          </S.ControlWidth>
        </S.Portfolio>
        <S.ButtonPortfolio>
          <Button variant="gradient" href="/cases" placeholder="Ver todos os cases" />
        </S.ButtonPortfolio>
      </Container>
    </div>
  );
}
