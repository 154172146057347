/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Player } from '@lottiefiles/react-lottie-player';
import LazyLoad from 'react-lazyload';
import * as S from './styles';
import Animation from '../../assets/images/portfolio/Animation.json';

export default function PortfolioMobile({ data }) {
  return (
    <>
      <S.FirstSection>
        <div
          className="capa"
          style={{ backgroundImage: `url(${data.capa})` }}
        />
        <div className="px-3">
          <img src={data.logo} className="logo mt-3" width={data.logoWidth} alt="" />
          <div>
            <h1>
              {data.title}
            </h1>
            <p>{data.text}</p>
          </div>

          <div className={`tags ${data.parceiros ? 'have-partner' : 'nohave-partner'}`}>
            {data.tags.map(({ tag }) => (
              <div>
                <span>{tag}</span>
              </div>
            ))}
          </div>

          {data.parceiros && (
            <div className="parceiros">
              {data.parceiros.map(({ tipo, parceiro, link }) => (
                <a href={link} target="_blank" rel="noreferrer">
                  <span>
                    {tipo}
                    {' '}
                    {parceiro}
                  </span>
                </a>
              ))}
            </div>
          )}

          <div className="animation">
            <Player
              autoplay
              loop
              src={Animation}
            />
          </div>

        </div>
      </S.FirstSection>
      <LazyLoad offset={100} once>
        <S.Content>
          {data.photos.map(({ url }) => (
            <img src={url} alt="" />
          ))}
        </S.Content>
      </LazyLoad>
      <S.MoreCases data-scroll>
        <a href="/cases">
          mais cases
        </a>
      </S.MoreCases>
    </>
  );
}
